import React, { useEffect, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import { ParallaxBanner } from 'react-scroll-parallax';
import Image from 'gatsby-image';
import { RichText } from 'prismic-reactjs';
import { Textfit } from 'react-textfit';
import ReactModal from '../reactModal.js';

const ParallaxBackground = styled(Image)`
  width: 100%;
  height: 110%;
  //object-fit: cover;
  //display: block;
  //object-position: center;
  //position: relative;
  //top: -50%;

  @media (orientation: portrait) {
    display: none;
  }
`;

const ParallaxBackgroundPortrait = styled(Image)`
  width: 100%;
  height: 110%;

  @media (orientation: landscape) {
    display: none;
  }
`;

const HeroBanner = styled(ParallaxBanner)`
  background: #064f71;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100) - 56px);
  min-height: 480px;

  @media (min-width: 600px) {
    min-height: 608px;
  }

  @media (min-width: 900px) {
    min-height: 752px;
  }

  @media (min-width: 1200px) {
    min-height: 928px;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    height: calc((var(--vh, 1vh) * 100) - 96px);
  }

  @media (min-width: 900px) {
    height: calc((var(--vh, 1vh) * 100) - 96px);
  }
`;

const HeroSubtitle = styled(Textfit)`
  width: 60%;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  letter-spacing: 2px;
  color: #fff;
  line-height: 1;
  font-size: 2rem;
`;

const HeroTitle = styled(Textfit)`
  color: #fff;
  line-height: 1.2;
  font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  letter-spacing: 2px;
  font-size: 4rem;
`;
//
const HeroOrderNow = styled(Textfit)`
  color: #0d3349;
  line-height: 1;
  font-family: 'Displayed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  //font-style: oblique;
  font-size: 3.5rem;
  width: 50%;
  margin: 1rem auto 0;
  cursor: pointer;
  padding: 1rem 3rem;
  background: #fff;
  border-radius: 999px;
`;

const HeroOrderButtons = styled.div`
  //@media (min-width: 600px) {
  //  display: flex;
  //  justify-content: center;
  //}
`;
const HeroOrderButton = styled.div`
  border-radius: 6px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  margin: 16px auto 0;

  @media (min-width: 600px) {
    //flex-direction: column;
    width: 240px;
    //margin: 16px;
  }

  @media (min-width: 900px) {
    width: 256px;
    //  margin-left: 32px;
    //  margin-right: 32px;
  }
`;
const HeroOrderButtonText = styled.h2`
  color: #064f71;
  flex: 1;
  margin-left: 16px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.2rem;

  @media (min-width: 600px) {
    //margin-left: 0;
    margin-top: 8px;
  }

  @media (min-width: 900px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
  }
`;
const HeroOrderServiceContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HeroOrderServiceImage = styled.a`
  width: 48px;
  opacity: 0.8;
  margin-left: 4px;
  margin-right: 4px;
  padding: 8px;

  //@media (min-width: 900px) {
  //  width: 64px;
  //}
`;

const ParallaxContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (orientation: portrait) {
    padding-top: 32px;
    justify-content: flex-start;
  }

  //@media (orientation: landscape) {
  //  width: 50%;
  //  right: 0;
  //}
`;

const PageInnerContainer = styled.div`
  opacity: ${props => (props.ready ? '1' : '0')};
  transition: opacity 1s;
  margin-top: 8rem;

  @media (orientation: landscape) {
    margin-top: 0;
    margin-left: 55%;
    width: 45%;
    transform: translateX(2rem);

    @media (min-width: 1500px) {
      transform: translateX(6rem);
    }
  }
`;

const Modal = styled(ReactModal)`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    overflow: hidden;
    display: flex;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  &__content {
    outline: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;

const ChoiceContainer = styled.div`
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
`;

const Choice = styled.a`
  display: block;
  border: 2px solid #064f71;
  border-radius: 4px;
  padding: 8px 24px;
  margin-top: 16px;
  color: #064f71;
  text-decoration: none;
  text-align: center;

  &:first-child {
    margin-top: 0;
  }
`;

const Hero01 = () => {
  const [ready, setReady] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(function() {
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      setReady(true);
    }, 500);
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query {
          prismic {
            allHome_pages {
              edges {
                node {
                  banner_1_image
                  banner_1_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  banner_1_portrait_image
                  banner_1_portrait_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  banner_1_title
                  banner_1_subtitle
                  deliveroo_image
                  deliveroo_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 96, maxHeight: 96) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  food_panda_image
                  food_panda_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 96, maxHeight: 96) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  order_location_1
                  order_location_1_deliveroo {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  order_location_1_food_panda {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                  order_location_2
                  order_location_2_deliveroo {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  order_location_2_food_panda {
                    ... on PRISMIC__ExternalLink {
                      _linkType
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
        if (!node) {
          return null;
        }

        const {
          banner_1_image,
          banner_1_imageSharp,
          banner_1_portrait_image,
          banner_1_portrait_imageSharp,
          banner_1_title,
          banner_1_subtitle,
          // deliveroo_image,
          // deliveroo_imageSharp,
          // food_panda_image,
          // food_panda_imageSharp,
          // order_location_1,
          // order_location_1_deliveroo,
          // order_location_1_food_panda,
          // order_location_2,
          // order_location_2_deliveroo,
          // order_location_2_food_panda,
        } = node;

        if (!banner_1_imageSharp || !banner_1_portrait_image) {
          return null;
        }

        return (
          <section>
            <Modal
              isOpen={isActive}
              onRequestClose={() => setIsActive(false)}
              closeTimeoutMS={500}
            >
              <ChoiceContainer>
                <Choice
                  href={'https://deliveroo.hk/en/brands/honbo'}
                  target={'_blank'}
                >
                  Deliveroo
                </Choice>
                <Choice href={'https://www.honbo.delivery/'} target={'_blank'}>
                  Honbo Delivery (HK Wide)
                </Choice>
              </ChoiceContainer>
            </Modal>
            <HeroBanner
              style={{
                height: null,
              }}
              layers={[
                {
                  expanded: false,
                  children: (
                    <ParallaxBackground
                      // loading={'eager'}
                      fluid={banner_1_imageSharp.childImageSharp.fluid}
                      alt={banner_1_image.alt}
                    />
                  ),
                  amount: 0.3,
                },
                {
                  expanded: false,
                  children: (
                    <ParallaxBackgroundPortrait
                      fluid={banner_1_portrait_imageSharp.childImageSharp.fluid}
                      alt={banner_1_portrait_image.alt}
                    />
                  ),
                  amount: 0.3,
                  // image: banner_1_imageSharp.childImageSharp.fluid.src
                },
              ]}
            >
              <ParallaxContainer>
                <PageContainer>
                  <PageInnerContainer ready={ready}>
                    <HeroSubtitle mode="single" max={1000}>
                      {RichText.asText(banner_1_subtitle)}
                    </HeroSubtitle>
                    <HeroTitle mode="single" max={1000}>
                      {RichText.asText(banner_1_title)}
                    </HeroTitle>
                    <HeroOrderNow
                      mode="single"
                      max={1000}
                      onClick={() => setIsActive(true)}
                    >
                      Order now
                    </HeroOrderNow>
                  </PageInnerContainer>
                </PageContainer>
              </ParallaxContainer>
            </HeroBanner>
          </section>
        );
      }}
    />
  );
};

export default Hero01;
