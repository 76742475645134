import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import { RichText } from 'prismic-reactjs';
import { ParallaxBanner } from 'react-scroll-parallax';
import Image from 'gatsby-image';

const Container = styled.section`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;
const ParallaxBackground = styled(Image)`
  width: 100%;
  height: 115%;
  //object-fit: cover;
  //display: block;
  //object-position: left center;
  //position: relative;
  //top: -25%;
`;

const SectionBanner = styled(ParallaxBanner)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 256px;

  @media (min-width: 900px) {
    min-height: 384px;
  }

  @media (min-width: 1200px) {
    min-height: 560px;
  }
`;

const SectionBannerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex-direction: column;
  color: #fff;
`;

const SectionBannerTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
  width: 240px;
  text-align: right;
  font-size: 2.2rem;
  line-height: 1.2;

  @media (min-width: 600px) and (orientation: portrait) {
    width: 256px;
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    width: 400px;
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    width: 600px;
    font-size: 8rem;
  }
`;

const SectionBannerSubTitle = styled.h6`
  margin-bottom: 0;
  margin-top: 8px;
  font-size: 1.2rem;
  letter-spacing: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  @media (min-width: 900px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
  }
`;

const SectionBannerInnerContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const MenuBanner07 = () => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHome_pages {
            edges {
              node {
                banner_3_image
                banner_3_imageSharp {
                  childImageSharp {
                    fluid(maxWidth: 1024, maxHeight: 1024) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
                banner_3_text
                banner_3_subtext
                banner_3_subtext_link
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const {
        banner_3_imageSharp,
        banner_3_text,
        banner_3_subtext,
        banner_3_subtext_link,
      } = node;

      if (!banner_3_imageSharp) {
        return null;
      }

      return (
        <Container>
          <SectionBanner
            style={{
              height: null,
            }}
            layers={[
              {
                expanded: false,
                children: (
                  <ParallaxBackground
                    fluid={banner_3_imageSharp.childImageSharp.fluid}
                    alt={banner_3_imageSharp.alt}
                  />
                ),
                amount: 0.3,
              },
            ]}
          >
            <SectionBannerContainer>
              <SectionBannerInnerContainer>
                {banner_3_text && (
                  <SectionBannerTitle>
                    {RichText.asText(banner_3_text)}
                  </SectionBannerTitle>
                )}
                {banner_3_subtext &&
                  (banner_3_subtext_link ? (
                    <Link to={banner_3_subtext_link}>
                      <SectionBannerSubTitle>
                        {RichText.asText(banner_3_subtext)}
                      </SectionBannerSubTitle>
                    </Link>
                  ) : (
                    <SectionBannerSubTitle>
                      {RichText.asText(banner_3_subtext)}
                    </SectionBannerSubTitle>
                  ))}
              </SectionBannerInnerContainer>
            </SectionBannerContainer>
          </SectionBanner>
        </Container>
      );
    }}
  />
);

export default MenuBanner07;
