import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import Image from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

const PhotoSection = styled.section`
  margin-top: 64px;
  padding: 0 16px;

  @media (min-width: 600px) {
    padding: 0 32px;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 96px;
  }

  @media (min-width: 900px) {
    margin-top: 96px;
  }
`;

const PhotoContainer = styled.div`
  margin: -8px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    margin: -16px;
  }
`;

const PhotoWrapper = styled.div`
  position: relative;
  padding: 8px;
  width: 100%;

  @media (min-width: 900px) {
    width: 33.3333%;
  }

  @media (min-width: 1200px) {
    //width: 33.3333%;
    padding: 16px;
  }
`;

const PhotoText = styled.h6`
  margin-bottom: 0;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  font-size: 1.2rem;
  text-align: center;
  color: #000;

  @media (min-width: 900px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem;
  }
`;

const Dishes03 = () => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHome_pages {
            edges {
              node {
                dishes {
                  dish_caption
                  dish_image
                  dish_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { dishes } = node;

      if (!dishes) {
        return null;
      }

      return (
        <PhotoSection>
          <PhotoContainer>
            {dishes.map((dish, index) => {
              return (
                <PhotoWrapper key={index}>
                  <Image fluid={dish.dish_imageSharp.childImageSharp.fluid} />
                  {dish.dish_caption && (
                    <PhotoText>{RichText.asText(dish.dish_caption)}</PhotoText>
                  )}
                </PhotoWrapper>
              );
            })}
          </PhotoContainer>
        </PhotoSection>
      );
    }}
  />
);

export default Dishes03;
