import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import { RichText } from 'prismic-reactjs';

const TextSection = styled.div`
  margin-top: 32px;

  @media (min-width: 600px) {
    display: flex;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;
const TextTitle = styled.h2`
  margin: 0;
  color: #064f71;
  font-size: 2.2rem;
  line-height: 1.2;

  @media (min-width: 600px) {
    font-size: 3rem;
    padding-right: 32px;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const TextSubSectionLeft = styled.div`
  @media (min-width: 600px) {
    width: 40%;
  }

  @media (min-width: 900px) {
    width: 50%;
  }
`;

const TextSubSection = styled.div`
  @media (min-width: 600px) {
    flex: 1;
  }
`;

const TextParagraph = styled.div`
  margin-top: 16px;

  @media (min-width: 600px) {
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    font-size: 1.2rem;
  }
`;

const TextDivider = styled.hr`
  margin-top: 16px;
  margin-bottom: 16px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-bottom: 1px solid #000;
  opacity: 0.2;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  @media (min-width: 900px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
const TextCaption = styled.p`
  font-weight: bold;
  @media (min-width: 1200px) {
    font-size: 1.2rem;
  }
`;

const Anchor = styled.a`
  display: block;
  position: relative;
  top: -88px;

  @media (min-width: 600px) and (orientation: portrait) {
    top: -160px;
  }

  @media (min-width: 900px) {
    top: -160px;
  }
  visibility: hidden;
`;

const OurStory02 = () => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHome_pages {
            edges {
              node {
                our_story_title
                our_story_text
                our_story_subtitle
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { our_story_title, our_story_text, our_story_subtitle } = node;

      return (
        <section>
          <PageContainer>
            <Anchor id="about" />
            <TextSection>
              <TextSubSectionLeft>
                {our_story_title && (
                  <TextTitle>{RichText.asText(our_story_title)}</TextTitle>
                )}
              </TextSubSectionLeft>
              {our_story_text && (
                <TextSubSection>
                  <TextParagraph>
                    <RichText render={our_story_text} />
                  </TextParagraph>
                  <TextDivider />
                  {our_story_subtitle && (
                    <TextCaption>
                      {RichText.asText(our_story_subtitle)}
                    </TextCaption>
                  )}
                </TextSubSection>
              )}
            </TextSection>
          </PageContainer>
        </section>
      );
    }}
  />
);

export default OurStory02;
