import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import Image from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

const Container = styled.section`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;

const Anchor = styled.a`
  display: block;
  position: relative;
  top: -88px;

  @media (min-width: 600px) and (orientation: portrait) {
    top: -160px;
  }

  @media (min-width: 900px) {
    top: -160px;
  }
  visibility: hidden;
`;

const Title = styled.h2`
  margin: 0;
  color: #064f71;
  font-size: 2.2rem;
  line-height: 1.2;
  text-align: center;

  @media (min-width: 600px) and (orientation: portrait) {
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const Menus = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;
`;

const MenuContainer = styled(Link)`
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 20px;
  overflow: hidden;
  margin: 10px;
  position: relative;
`;

const MenuName = styled.h3`
  position: absolute;
  padding: 0;
  z-index: 1;
  top: 50%;
  right: 1rem;
  margin: 0;
  line-height: 1;
  transform: translate(0, -50%);
  font-size: 2rem;
  color: #fff;
`;

const Menu10 = () => (
  <StaticQuery
    query={graphql`
      query {
        prismic {
          allHome_pages {
            edges {
              node {
                menu_pages {
                  menu_page {
                    ... on PRISMIC_Menu_page {
                      title
                      _meta {
                        uid
                      }
                      main_image
                      main_imageSharp {
                        childImageSharp {
                          fluid(maxWidth: 320, maxHeight: 160) {
                            ...GatsbyImageSharpFluid_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { menu_pages } = node;

      if (!menu_pages) {
        return;
      }

      return (
        <Container>
          <PageContainer>
            <Anchor id="menu" />
            <Title>Menu</Title>
            <Menus>
              {menu_pages.map((menu_page, index) => {
                return (
                  <MenuContainer
                    key={index}
                    to={`/menu/${menu_page.menu_page._meta.uid}`}
                  >
                    {menu_page.menu_page.main_imageSharp && (
                      <Image
                        fluid={
                          menu_page.menu_page.main_imageSharp.childImageSharp
                            .fluid
                        }
                      />
                    )}
                    {menu_page.menu_page.title && (
                      <MenuName>
                        {RichText.asText(menu_page.menu_page.title)}
                      </MenuName>
                    )}
                  </MenuContainer>
                );
              })}
            </Menus>
          </PageContainer>
        </Container>
      );
    }}
  />
);

export default Menu10;
