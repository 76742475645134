import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import { RichText } from 'prismic-reactjs';

const TextSection = styled.div`
  margin-top: 32px;

  @media (min-width: 600px) {
    display: flex;
  }

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;
const TextTitle = styled.h2`
  margin: 0;
  color: #064f71;
  font-size: 2.2rem;
  line-height: 1.2;

  @media (min-width: 600px) {
    font-size: 3rem;
    padding-right: 32px;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const TextSubSectionLeft = styled.div`
  @media (min-width: 600px) {
    width: 40%;
  }

  @media (min-width: 900px) {
    width: 50%;
  }
`;

const TextSubSection = styled.div`
  @media (min-width: 600px) {
    flex: 1;
  }
`;

const TextParagraph = styled.div`
  margin-top: 16px;

  @media (min-width: 600px) {
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    font-size: 1.2rem;
  }
`;

const Quality04 = () => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "rocket.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048, maxHeight: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                quality_title
                quality_text
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { quality_title, quality_text } = node;

      return (
        <section>
          <PageContainer>
            <TextSection>
              <TextSubSectionLeft>
                {quality_title && (
                  <TextTitle>{RichText.asText(quality_title)}</TextTitle>
                )}
              </TextSubSectionLeft>
              {quality_text && (
                <TextSubSection>
                  <TextParagraph>
                    <RichText render={quality_text} />
                  </TextParagraph>
                </TextSubSection>
              )}
            </TextSection>
          </PageContainer>
        </section>
      );
    }}
  />
);

export default Quality04;
