import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import PageContainer from '../pageContainer';
import Image from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

const Container = styled.section`
  margin-top: 32px;

  @media (min-width: 600px) and (orientation: portrait) {
    margin-top: 64px;
  }

  @media (min-width: 900px) {
    margin-top: 64px;
  }

  @media (min-width: 1200px) {
    margin-top: 96px;
  }
`;
const LocationSectionMobile = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;

const LocationSectionDesktop = styled.div`
  display: none;

  @media (min-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: -16px;
  }
`;

const LocationTitle = styled.h2`
  margin: 0;
  color: #064f71;
  font-size: 2.2rem;
  line-height: 1.2;
  text-align: center;

  @media (min-width: 600px) and (orientation: portrait) {
    font-size: 3rem;
  }

  @media (min-width: 900px) {
    font-size: 5rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;
const LocationContainer = styled.div`
  margin-top: 32px;

  &:first-child {
    margin-top: 16px;
  }
  @media (min-width: 600px) {
    display: flex;
    width: 100%;
  }

  @media (min-width: 900px) {
    display: block;
    //
    width: 233px;
    padding: 16px;
    &:first-child {
      margin-top: 32px;
    }
  }

  //@media (min-width: 1200px) {
  //margin-top: 32px;
  //}
`;

const LocationGroupDesktop = styled.div`
  display: none;
  @media (min-width: 900px) {
    display: block;
  }
`;
const LocationGroup = styled.div``;

const LocationGroupTitle = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 600px) and (orientation: portrait) {
    font-size: 2rem;
  }

  @media (min-width: 900px) {
    font-size: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 5rem;
  }
`;

const LocationContainerA = styled(Link)`
  margin-top: 32px;

  &:first-child {
    margin-top: 16px;
  }

  @media (min-width: 900px) {
    //display: flex;
    //width: 100%;
    width: 233px;
    padding: 16px;
    text-decoration: none;

    &:first-child {
      margin-top: 32px;
    }
  }

  //@media (min-width: 1200px) {
  //margin-top: 32px;
  //}
`;
const LocationInnerContainer = styled.div`
  @media (min-width: 600px) {
    padding-left: 32px;
    flex: 1;
  }

  @media (min-width: 900px) {
    padding-left: 0;
  }
`;
const LocationImage = styled.div`
  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 900px) {
    width: 100%;
  }
`;
const LocationName = styled.h3`
  font-size: 1.4rem;
  margin-bottom: 0;
  margin-top: 16px;

  @media (min-width: 600px) {
    font-size: 1.6rem;
    margin-top: 0;
    line-height: 1.2;
  }

  @media (min-width: 900px) {
    font-size: 1.6rem;
    //margin-top: 0;
    margin-top: 16px;
    line-height: 1.2;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
    //margin-top: 0;
  }
`;
const LocationDetail = styled.div`
  margin-top: 16px;
  p {
    margin: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 32px;
    font-size: 1.2rem;
  }
`;
const LocationDetailLink = styled.a`
  margin-top: 16px;
  display: block;
  p {
    margin: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 32px;
    font-size: 1.2rem;
  }
`;
const LocationButton = styled.a`
  border: 2px solid #064f71;
  border-radius: 4px;
  padding: 8px 24px;
  display: inline-block;
  margin-top: 16px;
  color: #064f71;
  text-decoration: none;
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: 1200px) {
    margin-top: 32px;
    font-size: 1.2rem;
  }
`;

const LocationAnchor = styled.a`
  display: block;
  position: relative;
  top: -88px;

  @media (min-width: 600px) and (orientation: portrait) {
    top: -160px;
  }

  @media (min-width: 900px) {
    top: -160px;
  }
  visibility: hidden;
`;

const LocationLinks = styled.div`
  display: flex;
  margin-top: 8px;
`;

const LocationLink = styled.a`
  display: block;
  text-decoration: none;
  border: 1px solid #064f71;
  border-radius: 99px;
  color: #064f71;
  padding: 4px 12px;
  margin-left: 8px;
  font-size: 0.9rem;

  &:first-child {
    margin-left: 0;
  }
`;

const ExtraButtons = styled.div``;

const Location08 = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { id: { regex: "/https/" } }) {
          edges {
            node {
              publicURL
              id
            }
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                locations_group {
                  location_name
                }
                locations {
                  group
                  location_image
                  location_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 512, maxHeight: 512) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                  location_title
                  location_address
                  location_hour
                  location_phone
                  link_1_title
                  link_1_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  link_2_title
                  link_2_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  link_3_title
                  link_3_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  link_4_title
                  link_4_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  link_5_title
                  link_5_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  menu {
                    ... on PRISMIC__FileLink {
                      _linkType
                      url
                    }
                  }
                  map_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const fileEdges = data.allFile.edges;

      const { locations, locations_group } = node;

      if (!locations) {
        return;
      }

      const locationGroups = locations_group.map(group => {
        const name = RichText.asText(group.location_name);
        const shops = locations.filter(location => {
          return RichText.asText(location.group) === name;
        });
        return {
          name,
          shops,
        };
      });

      return (
        <Container>
          <PageContainer>
            <LocationAnchor id="location" />
            <LocationTitle>Shops</LocationTitle>
            {locationGroups.map((group, index) => {
              if (group.shops.length < 1) {
                return null;
              }

              return (
                <LocationGroupDesktop key={index}>
                  <LocationGroupTitle>{group.name}</LocationGroupTitle>
                  <LocationSectionDesktop>
                    {group.shops.map((location, index) => {
                      if (location.menu?.url) {
                        for (let i = 0; i < fileEdges.length; i++) {
                          const fileEdge = fileEdges[i];
                          if (fileEdge.node.id === location.menu.url) {
                            location.menuUrl = fileEdge.node.publicURL;
                            break;
                          }
                        }
                      }

                      return (
                        <LocationContainerA key={index} to={'/shops'}>
                          <LocationImage>
                            {location.location_imageSharp && (
                              <Image
                                fluid={
                                  location.location_imageSharp.childImageSharp
                                    .fluid
                                }
                              />
                            )}
                          </LocationImage>
                          <LocationInnerContainer>
                            {location.location_title && (
                              <LocationName>
                                {RichText.asText(location.location_title)}
                              </LocationName>
                            )}
                          </LocationInnerContainer>
                        </LocationContainerA>
                      );
                    })}
                  </LocationSectionDesktop>
                </LocationGroupDesktop>
              );
            })}
            <LocationSectionMobile>
              {locationGroups.map((group, index) => {
                if (group.shops.length < 1) {
                  return null;
                }

                return (
                  <>
                    <LocationGroupTitle>{group.name}</LocationGroupTitle>
                    {group.shops.map((location, index) => {
                      if (location.menu?.url) {
                        for (let i = 0; i < fileEdges.length; i++) {
                          const fileEdge = fileEdges[i];
                          if (fileEdge.node.id === location.menu.url) {
                            location.menuUrl = fileEdge.node.publicURL;
                            break;
                          }
                        }
                      }

                      return (
                        <LocationContainer key={index}>
                          <LocationImage>
                            {location.location_imageSharp && (
                              <Image
                                fluid={
                                  location.location_imageSharp.childImageSharp
                                    .fluid
                                }
                              />
                            )}
                          </LocationImage>
                          <LocationInnerContainer>
                            {location.location_title && (
                              <LocationName>
                                {RichText.asText(location.location_title)}
                              </LocationName>
                            )}
                            {/* <LocationLinks>
                        {location.link_1_title && location.link_1_link && (
                          <LocationLink
                            href={location.link_1_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(location.link_1_title)}
                          </LocationLink>
                        )}
                        {location.link_2_title && location.link_2_link && (
                          <LocationLink
                            href={location.link_2_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(location.link_2_title)}
                          </LocationLink>
                        )}
                        {location.link_3_title && location.link_3_link && (
                          <LocationLink
                            href={location.link_3_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(location.link_3_title)}
                          </LocationLink>
                        )}
                        {location.link_4_title && location.link_4_link && (
                          <LocationLink
                            href={location.link_4_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(location.link_4_title)}
                          </LocationLink>
                        )}
                        {location.link_5_title && location.link_5_link && (
                          <LocationLink
                            href={location.link_5_link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {RichText.asText(location.link_5_title)}
                          </LocationLink>
                        )}
                      </LocationLinks> */}
                            {location.location_address && (
                              <LocationDetail>
                                <RichText render={location.location_address} />
                              </LocationDetail>
                            )}
                            {location.location_hour && (
                              <LocationDetail>
                                <RichText render={location.location_hour} />
                              </LocationDetail>
                            )}
                            {location.location_phone && (
                              <LocationDetailLink
                                href={
                                  'tel:+852' +
                                  RichText.asText(
                                    location.location_phone
                                  ).replace(/[^0-9]/g, '')
                                }
                              >
                                <RichText render={location.location_phone} />
                              </LocationDetailLink>
                            )}
                            <ExtraButtons>
                              {location.menuUrl && (
                                <LocationButton
                                  href={location.menuUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Menu
                                </LocationButton>
                              )}
                              {location.map_link && (
                                <LocationButton
                                  href={location.map_link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Map
                                </LocationButton>
                              )}
                            </ExtraButtons>
                          </LocationInnerContainer>
                        </LocationContainer>
                      );
                    })}
                  </>
                );
              })}
            </LocationSectionMobile>
          </PageContainer>
        </Container>
      );
    }}
  />
);

export default Location08;
